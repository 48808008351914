import { Link } from 'gatsby';
import { useCallback, useState } from 'react';
import QRCode from 'react-qr-code';
import { GoalProgressBar } from '../components/Goal/GoalProgressBar';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { Tickets } from '../components/Tickets';
import { ENABLED_CRYPTOCURRENCIES } from '../constants';
import { ORGANIZATIONS } from '../data/organizations';
import { useMoneyStatistics } from '../hooks/useMoneyStatistics';
import { useReveal } from '../hooks/useReveal';
import { Cryptocurrency } from '../types';
import {
  AnalyticsEvent,
  OutboundLink,
  trackCustomEvent,
} from '../utils/analytics';
import { formatPrice } from '../utils/priceFormatter';

const CRYPTO_MODAL_ID = 'crypto-modal';

const DonatePage = () => {
  useReveal();
  const { target } = useMoneyStatistics();

  const [selectedCrypto, setSelectedCrypto] = useState<Cryptocurrency | null>(
    null
  );
  const [copiedCrypto, setCopiedCrypto] = useState<string | null>();

  const onCopy = useCallback((currency) => {
    navigator.clipboard.writeText(currency.address);
    setCopiedCrypto(currency.symbol);
    setTimeout(() => setCopiedCrypto(null), 2000);
    trackCustomEvent({
      category: 'Donate',
      action: AnalyticsEvent.CopyCryptoAddress,
      label: `${currency.name} (${currency.symbol})`,
    });
  }, []);

  return (
    <>
      <SEO title="Donate" />
      <Layout>
        <div className="container mx-auto pt-12 md:pt-24 pb-14 px-4 flex flex-col md:items-center reveal fade-bottom">
          <h2 className="font-display text-3xl md:text-4xl lg:text-6xl md:text-center">
            Our <span className="font-display-alt">g</span>oal i
            <span className="font-display-alt">s</span> to rais
            <span className="font-display-alt">e</span>{' '}
            {formatPrice(target).split(',')[0]}K
          </h2>

          <div className="my-4 md:my-8 md:w-10/12 lg:w-8/12 xl:w-6/12 flex flex-col md:items-center">
            <p className="md:text-center opacity-70">
              This roughly equals to 1000 medical kits, 400 body armours, or 74
              tons of humanitarian aid, that could save thousands of Ukrainians'
              lives.
            </p>
          </div>

          <GoalProgressBar />
        </div>

        <Tickets showTitle={false} showDescription={false} />

        <div className="container mx-auto pt-2 md:pt-8 pb-8 md:pb-20 px-4 flex flex-col md:items-center reveal fade-bottom">
          <h6 className="text-2xl md:text-3xl md:text-center">Donate crypto</h6>

          <div className="mt-4 md:w-10/12 lg:w-8/12 gap-4 md:gap-x-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
            {ENABLED_CRYPTOCURRENCIES.map((currency) => (
              <label
                key={currency.symbol}
                htmlFor={CRYPTO_MODAL_ID}
                className="btn gap-2"
                onClick={() => setSelectedCrypto(currency)}
              >
                <div className="h-7 w-7">{currency.logo}</div>
                {currency.name}{' '}
                <span className="opacity-70">({currency.symbol})</span>
              </label>
            ))}
          </div>
          <p className="mt-4 opacity-70 md:text-center">
            *we update statistics a few times a day
          </p>
        </div>

        <div className="container mx-auto pt-8 md:pt-16 pb-8 md:pb-16 px-4 flex flex-col md:items-center reveal fade-bottom">
          <div className="md:w-10/12 lg:w-8/12 xl:w-6/12 flex flex-col md:items-center">
            <p className="md:text-center opacity-70">
              All funds raised during the event will be split between 3
              Non-Governmental Organizations & Humanitarian funds that we are
              collaborating with:
            </p>

            <div className="mt-6 md:mt-8 grid grid-cols-2 gap-6 md:gap-12 lg:gap-24 md:grid-cols-3 justify-items-center">
              {ORGANIZATIONS.map(({ name, image, link, className }) => (
                <OutboundLink href={link} key={name} target="_blank">
                  <img
                    src={image}
                    alt={name}
                    className={`h-12 md:h-16 opacity-70 hover:opacity-100 transition-opacity duration-500 ${className}`}
                  />
                </OutboundLink>
              ))}
            </div>

            <Link
              to="/why-these-organizations"
              className="mt-6 opacity-70 underline underline-offset-4 md:text-center modal-button cursor-pointer"
            >
              Learn why we chose them
            </Link>
          </div>
        </div>
      </Layout>

      <input type="checkbox" id={CRYPTO_MODAL_ID} className="modal-toggle" />
      <label htmlFor={CRYPTO_MODAL_ID} className="modal cursor-pointer">
        <label className="modal-box relative" htmlFor={CRYPTO_MODAL_ID}>
          <label
            htmlFor={CRYPTO_MODAL_ID}
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>

          {selectedCrypto && (
            <div className="pb-4 flex flex-col items-center">
              <h5 className="text-2xl text-center">
                {selectedCrypto.name} ({selectedCrypto.symbol})
              </h5>

              <div className="mt-8">
                <div className="p-2 bg-white">
                  <QRCode value={selectedCrypto.address} />
                </div>
              </div>

              <p className="p-4 text-center break-all">
                {selectedCrypto.address}
              </p>

              <div className="relative">
                <button
                  className="btn btn-primary"
                  onClick={() => onCopy(selectedCrypto)}
                >
                  Copy address
                </button>
                <span
                  className={`absolute -bottom-5 left-1/2 -translate-x-1/2 text-xs text-secondary opacity-0 transition-opacity duration-500 ${
                    copiedCrypto === selectedCrypto.symbol ? 'opacity-100' : ''
                  }`}
                >
                  Copied
                </span>
              </div>
            </div>
          )}
        </label>
      </label>
    </>
  );
};

export default DonatePage;
