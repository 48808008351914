import { CookieConsent } from '../CookieConsent';
import { Footer } from '../Footer';
import { Navigation } from '../Navigation';

export const Layout = ({ children }: { children: any }) => {
  return (
    <main className="bg-primary-content">
      <Navigation />

      <div className="pt-[80px] md:pt-[160px]">{children}</div>

      <Footer />
      <CookieConsent />
    </main>
  );
};
